<template>
  <div>
    <div :class="img === 1 ? 'cloud_bg bg' : 'cloud_bg'">
      <div class="container">
        <slot name="cloud_title"></slot>
        <slot name="cloud_title_1"></slot>
        <slot name="cloud_des"></slot>
        <div class="btn_group flex">
          <div @click="isShowVideo = true">观看视频</div>
        </div>

        <div class="head">
          <slot name="head_title"></slot>
          <div class="head_list flex align-center">
            <div v-for="(item,index) in navList" :key="index" @click="scrollInto(index+1)">
              <a>{{item}}</a>
              <div :class="index === 0 ? 'position_line' : 'position_line hide'"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="table_change">
      <div v-for="item in list" :key="item.id" :id="`slide_${item.id}`" :class="item.id % 2 === 0 ? 'swiper-slide revserse_bg' : 'swiper-slide'">

        <div :class="item.id % 2 === 0 ? 'jingying flex align-center revserse' : 'jingying flex align-center'">
          <div class="img_left">
            <img :src="'http://1300718258.vod2.myqcloud.com/94092fe5vodcq1300718258/'+item.img" alt="">
          </div>
          <div class="right_list">
            <div class="right_head_btn">{{item.btn}}</div>
            <div class="right_head_des">{{item.des}}</div>
            <div class="right_head_main">
              {{item.main}}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="video_mask_in" v-show="isShowVideo">
      <video controls src="http://1300718258.vod2.myqcloud.com/94092fe5vodcq1300718258/c2a8d139387702298222085919/TpCMpLkpDB0A.mp4"></video>
      <img class="close_btn" src="../../../assets/img/close.png" alt="" @click="isShowVideo = false">
    </div>
  </div>
</template>

<script>
export default {
  props: ['navList', 'list', 'img'],
  data() {
    return {
      isShowVideo: false
    }
  },
  methods: {
    scrollInto(e) {
      this.$el
        .querySelector(`#slide_${e}`)
        .scrollIntoView({ behavior: 'smooth' })
    }
  }
}
</script>

<style lang="less" scoped>
.bg {
  background-image: url('http://1300718258.vod2.myqcloud.com/94092fe5vodcq1300718258/423048c0387702299482433749/f0.png') !important;
  background-repeat: no-repeat;
  background-size: 100% 95% !important;
  .btn_group{
    margin-bottom: 180px !important;
  }
}
.cloud_bg {
  background-image: url('http://1300718258.vod2.myqcloud.com/94092fe5vodcq1300718258/42546831387702299482453300/f0.png');
  background-size: cover;
  padding-top: 72px;
  padding-bottom: 12px;
  margin-bottom: 80px;
  .cloud_title {
    font-size: 30px;
    font-family: GraublauWeb;
    font-weight: 400;
    color: #ffffff;
    margin-bottom: 30px;
    text-align: center;
  }
  .cloud_title_1 {
    font-size: 30px;
    font-family: GraublauWeb;
    font-weight: 400;
    color: #ffffff;
    margin-bottom: 50px;
    text-align: center;
  }

  .cloud_des {
    width: 700px;
    font-size: 18px;
    font-family: GraublauWeb;
    font-weight: 400;
    color: #ffffff;
    line-height: 30px;
    margin: 0 auto;
    margin-bottom: 70px;
  }

  .btn_group {
    justify-content: center;
    margin-bottom: 178px;
    div {
      width: 160px;
      height: 44px;
      background: linear-gradient(150deg, #317bff, #1740df);
      box-shadow: 0px 1px 8px 0px rgba(0, 48, 101, 0.2);
      border-radius: 4px;
      line-height: 44px;
      text-align: center;
      font-size: 20px;
      font-family: GraublauWeb;
      font-weight: 400;
      color: #ffffff;
      margin: 0 39px;
      cursor: pointer;
    }
  }

  .head {
    .head_title {
      font-size: 30px;
      font-family: GraublauWeb;
      font-weight: 400;
      color: #1740df;
      margin-bottom: 42px;
      text-align: center;
    }

    .head_list {
      justify-content: space-between;
      width: 900px;
      border-bottom: 2px solid #e4eaff;
      margin: 0 auto;
      & > div {
        margin: 0 20px;
        padding-bottom: 22px;
        font-size: 20px;
        font-family: GraublauWeb;
        font-weight: 400;
        color: #333333;
        position: relative;
        cursor: pointer;
        .position_line {
          width: calc(100% + 40px);
          height: 4px;
          background: #1740df;
          position: absolute;
          bottom: -3px;
          margin-left: -20px;
          margin-right: -20px;
        }
        .hide {
          display: none;
        }
      }
    }
  }
}

.table_change {
  padding-bottom: 160px;
  .swiper-slide {
    background: #fff;
    .jingying {
      width: 1200px;
      margin: 0 auto;
      padding: 144px 0;
      .img_left {
        width: 660px;
        box-shadow: 0px 0px 8px 0px rgba(10, 0, 105, 0.2);
        margin-right: 68px;
        img {
          width: 100%;
        }
      }
      .right_list {
        flex: 1;
        .right_head_btn {
          width: 170px;
          height: 50px;
          background: linear-gradient(150deg, #317bff, #1740df);
          box-shadow: 0px 1px 8px 0px rgba(0, 48, 101, 0.2);
          border-radius: 4px;
          font-size: 28px;
          font-family: GraublauWeb;
          font-weight: 400;
          color: #ffffff;
          text-align: center;
          line-height: 50px;
          margin-bottom: 28px;
        }

        .right_head_des {
          font-size: 24px;
          font-family: GraublauWeb;
          font-weight: 400;
          color: #333333;
          margin-bottom: 22px;
        }

        .right_head_main {
          font-size: 18px;
          font-family: GraublauWeb;
          font-weight: 400;
          color: #333333;
          line-height: 30px;
        }
      }
    }
  }

  .revserse_bg {
    background: #f0f5fb;

    .revserse {
      flex-direction: row-reverse;
      .img_left {
        margin-right: 0;
        margin-left: 68px;
      }
    }
  }
}
.video_mask_in {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 999999999999999;
  video {
    position: absolute;
    left: 50%;
    top: 300px;
    width: 1000px;
    margin-left: -500px;
  }
}

.close_btn {
  position: absolute;
  top: 240px;
  left: 50%;
  margin-left: 500px;
  cursor: pointer;
}

@media all and (max-width: 1080px) {
  .bg {
    background-image: url('http://1300718258.vod2.myqcloud.com/94092fe5vodcq1300718258/91a45f63387702299483554495/f0.png') !important;
    background-size: 100% 75%;
  }
  .cloud_bg {
    background-image: url('http://1300718258.vod2.myqcloud.com/94092fe5vodcq1300718258/8aa564b8387702299483255162/f0.png');
    background-size: 100% 75% !important;
    padding: 40px 20px 10px;
    background-repeat: no-repeat;
    margin-bottom: 20px;
    .container {
      .cloud_title {
        font-size: 32px;
        margin-bottom: 30px;
      }
      .cloud_title_1_mini {
        margin-bottom: 50px;
      }
      .cloud_title_1 {
        font-size: 22px;
      }
      .cloud_des {
        width: 100%;
        font-size: 20px;
        margin-bottom: 50px;
      }
      .btn_group {
        display: flex !important;
        margin-bottom: 77px;
        div {
          margin: 0 10px;
        }
      }
      .head {
        .head_title {
          font-size: 22px;
        }
        .head_list {
          display: flex !important;
          width: 95%;
          flex-wrap: wrap;
          border-bottom: 0;
          justify-content: unset;
          & > div {
            margin: 0;
            padding: 10px 0;
            margin-bottom: 10px;
            width: 25%;
            text-align: center;
            box-sizing: border-box;
            font-size: 15px;
            color: #ffffff;
            &:first-child {
              border: 1px solid #1740df;
              border-radius: 7px;
            }
            &:first-child > a {
              color: #1740df;
            }
            .position_line {
              display: none;
            }
          }

          #slide_4 {
            margin-bottom: 0;
          }
        }
        .position_line {
          display: none;
        }
      }
    }
  }
  .table_change {
    .revserse_bg {
      .img_left {
        margin: 0;
        margin-bottom: 30px;
        margin-left: 0 !important;
      }
    }
    .jingying {
      display: flex !important;
      width: 100% !important;
      padding: 30px 0 !important;
      flex-direction: column-reverse !important;
      margin-bottom: 20px !important;
      .img_left {
        width: 100% !important;
        margin-bottom: 20px;
      }

      .right_list {
        padding: 0 20px;
        margin-bottom: 20px;
        .right_head_btn {
          width: 110px !important;
          height: 40px !important;
          font-size: 18px !important;
          line-height: 40px !important;
          letter-spacing: 2px;
        }
        .right_head_des {
          font-size: 1.1rem;
        }
      }
    }
  }

  .video_mask_in {
    img {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 90px;
      left: 88%;
      margin-left: 0;
      cursor: pointer;
    }
    video {
      position: absolute;
      top: 150px;
      left: 0;
      width: 100%;
      margin-left: 0;
      z-index: 999999999999999;
    }
  }
}
</style>