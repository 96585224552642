<template>
    <div>
        <Currency :navList='navList' :list='list'>
            <div slot="cloud_title" class="cloud_title">羽林二类电商云</div>
            <div slot="cloud_title_1" class="cloud_title_1">为二类电商短信营销打造客户分析与订单管理平台</div>
            <div slot="cloud_title_1" class="cloud_title_1 cloud_title_1_mini">
                <p>为二类电商短信营销</p>
                <p>打造客户分析与订单管理平台</p>
            </div>
            <div slot="cloud_des" class="cloud_des">专为二类电商打造的数字推广平台。通过大数据筛选，精准推荐客户进行短信营销，有效提高营销推广触达率，实现产品、品牌多倍曝光。产品曝光引入客户数据，追踪客户消费习惯，智能分析客户消费能力和增购复购机会，动动手指了解每天订单业务量，轻松掌握经营情况。</div>
            <div slot="head_title" class="head_title">羽林二类电商云</div>
        </Currency>

        <RightSuspension></RightSuspension>
    </div>
</template>

<script>
import Currency from './components/index'

import RightSuspension from '@/components/right_suspension'

export default {
  components: { RightSuspension, Currency },
  data() {
    return {
      navList: [
        '经营看板',
        '产品列表',
        '短信管理',
        '订单管理',
        '算法(ROI)',
        '系统'
      ],
      list: [
        {
          id: 1,
          img: '916e117d387702299483524384/f0.gif',
          btn: '经营看板 ',
          des: '二类商户每天的经营智能展示',
          main:
            '所有经营报表实时、全方位体现。助力经营者推广决策，从而提高决策效率，提升经营收益。'
        },
        {
          id: 2,
          img: '4278b2de387702299482473914/f0.gif',
          btn: '产品列表 ',
          des: '与产品链接对接',
          main:
            '轻松导入和分类管理产品。链接系统支持视频展示，嵌入客服在线咨询，大幅提升转化。同时甄别假单、异常单，实时掌握下单增加效益。'
        },
        {
          id: 3,
          img: '91924b20387702299483544592/f0.gif',
          btn: '短信管理 ',
          des: '与短信通道对接',
          main:
            '短信数据自主分类管理，通道发送推广营销短信，计费成本、状态、回复全部实时呈现，实现营销数字闭环。'
        },
        {
          id: 4,
          img: '46eb04d6387702299482640541/f0.gif',
          btn: '订单管理 ',
          des: '与订单系统对接 ',
          main:
            '详细掌握客户行为，实时转化核实订单。并无缝对接物流系统后，系统可以自动返回订单在物流系统中的实时状态，从而真正做到准确分析。'
        },
        {
          id: 5,
          img: '46eb11fe387702299482640873/f0.gif',
          btn: '算法(ROI) ',
          des: 'ROI部分主要通过数据算法做推荐',
          main:
            '通过不同产品、不同的号码批次、不同的时间段、不同的通道等，为用户推荐最佳推送时间、最优数据、最受欢迎的产品等，使收益效果达到最优。'
        },
        {
          id: 6,
          img: '46eb1663387702299482640986/f0.gif',
          btn: '系统 ',
          des: '阐释公司管理分层、人物构架',
          main:
            '这里主要是对销售人员的管理，用户可以通过管理决定订单分配，查看员工业务比重、完成情况。'
        }
      ]
    }
  }
}
</script>
<style lang="less">
.cloud_title_1_mini {
  display: none;
}
@media all and (max-width: 1080px) {
  .cloud_title_1 {
    display: none;
  }
  .cloud_title_1_mini {
    display: block;
  }
}
</style>
